import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const AboutUs = () => {

    return (
        <section className="about-us">
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="Poop info is about ran by a group of puperts. Our job is to help you understand poop."/>
            </Helmet>
            <h1>About us</h1>
            <p>
                We are a team of Pooperts. We believe that it is important to understand your poop. Why? because we also believe a clear understading of poop help our readers make better decision about the food they eat.
            </p>
            <p>
                This is the first website that focuses primary on poop. Please reach out to us if you would like to provide feedback. <Link to="/contact-us"> Contact us</Link>
            </p>
        </section>
    )
}

export default AboutUs;