import { NavLink } from "react-router-dom";
import "./color.style.scss";

interface Props {
    color: string
}
const Color = ({color}: Props)=> {
    return (
        <NavLink to={`/poop-color/${color}`}>
            <div className="color-item" style={{
                    backgroundColor: color
                }}>   
            </div>
        </NavLink>
     
    )
}

export default Color;