import { Helmet } from "react-helmet";

const TypeFivePoop = () => {
    return (
        <article>
            <Helmet>
                <title>Type 5 poop (Soft Blobs)</title>
                <meta name="description" content="This type of poop on the Bristol stool chart is made of soft blobs, learn more about this type of poop "/>  
            </Helmet>
            <h2>
                Type 5 poop (Soft Blobs)
            </h2>
            <p>
                This type of poop consist of soft blobs with defined edges and is very easy to pass. Individuals may feel a sense of urgency when heading to the toilet to pass this type of poop. If this is the case then this is a sign of mild diarrhea.
            </p>
            <h2>Remedies:</h2>
            <p>
                This is general an indication that you are lactking. Please increase your consumption of foods rich in fiber.
            </p>
        </article>
    )
}
export default TypeFivePoop;