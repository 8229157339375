import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

interface Props {
    question: string,
    answer: string
}
const TriviaCard = ({question, answer}: Props) => {
  const [showAsnwer, setShowAnswer] = useState(false);  
  return (
    <Card sx={{ minWidth: 275 }} className="trivia-card">
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Question
        </Typography>
      
        <Typography variant="body2">
          { question }
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
         <br />
          Answer
        </Typography>
        {
            showAsnwer 
            &&  <Typography variant="body2">
            { answer }
          </Typography>
        }
       

      </CardContent>
      <CardActions>
        <Button size="small" onClick={()=> setShowAnswer(!showAsnwer)}>show</Button>
      </CardActions>
    </Card>
  );
}
export default TriviaCard;