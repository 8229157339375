import './App.scss';
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import { Container, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
        <CssBaseline/>
        <Helmet>
          <title>Poop Info</title>
          <meta name="description" content="Poop Info - We help our readers better understand their poop. With this information users can make better decisions."/>  
         </Helmet>
        <Header/>
        <Container className='application-container'>
          <main>
              <Outlet/>
          </main>
        </Container>
        <Footer />
    </div>
  );
}

export default App;
