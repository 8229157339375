import { Route, Routes } from 'react-router-dom';
import Main from './components/home/home.component';
import ContactUs from './components/pages/contact-us/contact-us.component';
import AboutUs from './components/pages/about-us/about-us.component';
import PoopTrivia from './components/pages/poop-trivia/poop-trivia.component';
import PoopColor from './components/pages/poop-color/poop-color.component';
import PoopTypes from './components/pages/poop-types/poop-types.component';
import TypeOnePoop from './components/pages/poop-types/type-one-poop/type-one-poop.component';
import TypeTwoPoop from './components/pages/poop-types/type-two-poop/type-two-poop';
import TypeThreePoop from './components/pages/poop-types/type-three-poop/type-three-poop.component';
import TypeFourPoop from './components/pages/poop-types/type-four-poop/type-four-poop.component';
import TypeFivePoop from './components/pages/poop-types/type-five-poop/type-five-poop.component';
import TypeSixPoop from './components/pages/poop-types/type-six-poop/type-six-poop.component';
import TypeSevenPoop from './components/pages/poop-types/type-seven-poop/type-seven-poop.component';
import RedPoop from './components/pages/poop-color/red-poop/red-poop.component';
import BlackPoop from './components/pages/poop-color/black-poop/black-poop.component';
import GreenPoop from './components/pages/poop-color/green-poop/green-poop';
import YellowPoop from './components/pages/poop-color/yellow-poop/yellop-poop';
import WhitePoop from './components/pages/poop-color/white-poop/white-poop';
import BrownPoop from './components/pages/poop-color/brown-poop/brown-poop.component';
import App from './App';
import CoffeeAndPoop from './components/pages/coffee-make-you-poop/coffee-poop.component';

//TODO add a no match route
const appRoutes = () => (<Routes>
    <Route path="/" element={<App />}>
      <Route index element={<Main/>} />
      <Route path='contact-us' element={<ContactUs/>} />
      <Route path='about-us' element={<AboutUs/>} />
      <Route path='poop-trivia' element={<PoopTrivia/>} />
      <Route path='poop-color' element={<PoopColor/>} >
          <Route path="red" element={<RedPoop />}/>
          <Route path="black" element={<BlackPoop />}/>
          <Route path="green" element={<GreenPoop />}/>
          <Route path="yellow" element={<YellowPoop />}/>
          <Route path="white" element={<WhitePoop />}/>
          <Route path="brown" element={<BrownPoop />}/>
      </Route>
      <Route path='poop-types' element={<PoopTypes/>}>
          <Route path="marbles" element={<TypeOnePoop />}/>
          <Route path="lumpy-sausage" element={<TypeTwoPoop />}/>
          <Route path="sausage" element={<TypeThreePoop />}/>
          <Route path="smooth-sausage" element={<TypeFourPoop />}/>
          <Route path="soft-blobs" element={<TypeFivePoop />}/>
          <Route path="mushy" element={<TypeSixPoop />}/>
          <Route path="watery" element={<TypeSevenPoop />}/>
      </Route>
      <Route path='does-coffee-make-you-poop' element={<CoffeeAndPoop />} />
    </Route>
  </Routes>)

  export default appRoutes;