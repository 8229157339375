import { Helmet } from "react-helmet";

const TypeFourPoop = () => {
    return (
        <article>
            <Helmet>
                <title>Type 4 poop (Smooth Sausage)</title>
                <meta name="description" content="This type of poop on the Bristol stool chart is smooth, learn more about this type of poop "/>  
            </Helmet>
            <h2>Type 4 poop (Smooth Sausage)</h2>
            <p>
                Like type 3 poop doctors consider this type of poop to be normal. This type of poop is smooth, soft, has no cracks or lumps and is easy to pass. You should have 1 to 3 of these types of poops per week. 
            </p>
            <h2>Remedies: </h2>
            <p>
                 Continue maintaining your current dieatary habbits
            </p>
        </article>

    )
}

export default TypeFourPoop;