import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import CardsContainer from "../../card/cards-container.component";
import CoffeeAndPoopCard from "../coffee-make-you-poop/coffee-poop.card.component";
import PoopType from "./poop-type/poop-type.component";
import "./poop-types.style.scss"
interface Pooptypes {
    imageUrl: string,
    type: string,
    id: string
}
const PoopTypes = () => {
/**
 * Read the poop type from the url
 * Pass it to the selector and description 
 */
const poopTypes: Array<Pooptypes> = [
        {
            imageUrl:"type-one-poop_marbles.png",
            id:"1",
            type:"marbles"
        },
        {
            imageUrl:"type-two-poop_lumpy_sausage.png",
            id:"2",
            type: "lumpy-sausage"
        },
        {
            imageUrl:"type-three-poop_sausage.png",
            id:"3",
            type:"sausage"
        },
        {
            imageUrl:"type-four-poop_smooth_sausage.png",
            id:"4",
            type: "smooth-sausage"
        },
        {
            imageUrl:"type-five-poop_soft_blobs.png",
            id:"5",
            type:"soft-blobs"
        },
        {
            imageUrl:"type-six-poop_mushy.png",
            id:"6",
            type: "mushy"
        },
        {
            imageUrl:"type-seven-poop_watery.png",
            id:"7",
            type: "watery"
        }
    ]
    return (
        <Fragment>
            <header className="poop-types_header">
                <h1>The 7 types of <span className="poop">poop</span></h1>
                <p className="poop-sub-heading">
                    Describing poop can be challenging. 
                    That's why doctors use a tool called the Bristol Stool chart. 
                    Select each image to learn about its poop type.
                </p>
            </header>
            <section className="poop-types">
                <ul className="poop-types_list">
                    {
                    poopTypes.map((poopType) => (
                        <li  className="poop-item" key={ poopType.id }>
                            <PoopType  {...poopType}/> 
                        </li>
                    ))
                  }   
                </ul>
            </section>
            <section>
                <Outlet />
            </section>
            <CardsContainer>
                <CoffeeAndPoopCard/>
            </CardsContainer>
        </Fragment> 
    )
}

export default PoopTypes;