import { Link } from "react-router-dom";
import "./home.style.scss";
const Main = () => {

    return (
        <div className="home">
            <section className="banner">
                <h1 className="banner-heading">
                     Improve your knowledge of <span className="poop">poop</span>
                </h1>
                <p className="banner-message">
                    Poop can help you understand what is going on inside your body. Our goal is to help you better understand your poop.
                </p>
            </section>
            <section className="toilet-banner">
                <h1>Let's talk about what goes in the toilet</h1>
                <img src="images/toilet.webp" alt="toilet" className="toilet-image"/>
            </section>
            <section className="poop-chart feature container">
                <div className="feature_image">
                    <img src="images/poop.webp" alt="chart showing the different type of poop" className="poop-chart_image"/>
                </div>
                <div className="feature_description">
                    <p>
                        The Poop Chart shows the different type of
                        poop textures. These textures are good 
                        indicators of constipation, a lack of 
                        fiber and inflamtion. <Link to="/poop-types/marbles" className="poop-chart_learn_more">Learn more</Link>
                    </p>
                </div>
            </section>
            <section className="poop-color-wheel feature container">
                <div className="feature_image">
                    <img src="images/poop-color.webp" alt="chart showing the different type of poop" className="poop-chart_image"/>
                </div>
                <div className="feature_description">
                    <p>
                        Poop comes in many colors including yellow, green and red. Understanding these colors means we won't be surprise when we see a color were not expecting. <Link to="/poop-color/brown" className="poop-chart_learn_more">Learn more</Link>
                    </p>
                </div>
            </section>
            <section className="poop-trivia feature container">
            <div className="feature_image">
                    <img src="images/question.webp" alt="chart showing the different type of poop" className="poop-chart_image"/>
                </div>
                <div className="feature_description">
                    <p>
                        How long is largest poop on record? learn about this and more in our Poop Trivia section. <Link to="/poop-trivia" className="poop-chart_learn_more">Learn more</Link>
                    </p>
                </div>
            </section>
        </div>
    )
}

export default Main;