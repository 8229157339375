import { Helmet } from "react-helmet"

const WhitePoop = () => {
    return (
        <article>
            <Helmet>
                <title>Poop Info - White Poop</title>
                <meta name="description" content="Poop Info - Why is my poop white? Let's talk about white poop. White poop should immediately raise and alarm. This page focuses more on causes of white poop."/>  
           </Helmet>
            <h2>White poop</h2>
            <p>This type of poop is not normal; therefore, if you experience this type of poop you should consult a doctor. </p>
            <p>Some common causes for white poop are desease of the liver, Gallbladder problems, pancreatic diseases, and medications. For medications where this is a common symptom it should be listed as a side effect. </p>
            <h2>Remedies:</h2>
             <p>If you experience this type of poop please see your doctor.</p>
        </article>
    )
}
export default WhitePoop