import TriviaCard from "./trivia-card/trivia-card.component";
import "./poop-triva.style.scss";
import { Helmet } from "react-helmet";

const PoopTrivia = () => {
    const questionAndAns = [
        {
            question: "How many hours do you spend pooping per week?",
            answer: "On average we spend 3 hours pooping per week 😮 "
        },
        {
            question: "How long does it take for something you ate to excreted in your poop?",
            answer: "It normally takes 1 to 3 days"
        },
        {
            question: "75% of your poop is made up of?",
            answer: "Water 😮"
        },
        {
            question: "How long is largest poop on record?",
            answer: "The largest poop on record is 20 cm (0.65 ft)"
        },
        {
            question: "Coprophagics are people who eat?",
            answer: "Poop. Yes there are people that love to eat poop 🤮 🤷‍♀️. We love what we love 😊"
        },
        {
            question: "Some people feel and urge to poop when they visit this place.",
            answer: "What is the bookstore? Yes, some people feel the urge poop after visiting the book store. It is called Mariko Aoki phenomenon."
        },
        {
            question: "Which organ produces the substance that makes poop brown?",
            answer: "The liver. Bile produced by the liver is responsible for giving poop is brown color."
        }
    ]
    return (
        <section className="poop-trivia">
           <Helmet>
                <title>Poop Info - Mind blowing facts about poop. Everybody poops</title>
                <meta name="description" content="Poop trivia, interesting facts about poop that will amaze you."/>  
           </Helmet>
            {
                questionAndAns.map((qAndS)=> (
                    <TriviaCard {...qAndS}/>
                ))
            }
        </section>
    )
}

export default PoopTrivia;