import { Helmet } from "react-helmet";

const TypeThreePoop = () => {
    return (
        <article>
            <Helmet>
                <title>Type 3 poop (Smooth Sausage)</title>
                <meta name="description" content="This type of poop on the Bristol stool chart is smooth, learn more about this type of poop "/>  
            </Helmet>
            <h2>Type 3 poop (Smooth Sausage)</h2>

            <p>
                This type of poop is soft, and sausaged shaped with several cracks across the surface.  
            </p>

            <p>
                This type poop is considered normal. This is the type of poop you want to have. It should not take long to pass this type of poop. 
            </p>

            <h2>Remedies: </h2>
            <p>
                 Continue maintaining your current dieatary habits
            </p>
        </article>
    )
}

export default TypeThreePoop;