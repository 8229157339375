import Card from "../../card/card.component";

const CoffeeAndPoopCard = () => {
    return <Card
        title="The reason why coffee makes you poop"
        altText="image of coffee grains"
        description="Do you tumble out of bed and stumble to the kitchen to pour yourself a morning cup of ambition, 
        otherwise known as coffee?"
        imageUrl="/images/coffee-card.webp"
        href="/does-coffee-make-you-poop"
      />
}

export default CoffeeAndPoopCard;