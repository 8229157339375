import { Helmet } from "react-helmet";

const TypeTwoPoop = () => {
    return (
        <article className="type-two-poop">
             <Helmet>
                <title>Type 2 poop (Lumpy Suasage)</title>
                <meta name="description" content="We will discuss type two poop on the Bristol Stool chart."/>  
             </Helmet>
             <h2>Type 2 poop (Lumpy Suasage) </h2>

             <p>
                This type of poop is sausage shaped but lumpy. Similar to type one poop this is an indication that stool is moving slower than expected through the digestive track. These types of poops are also difficult to pass.
             </p>
             <p>
                Also, like type one this is an indicator of constipation; therefore, similar remedies apply.
             </p>

             <h2>Remedies: </h2>
                <ul>
                    <li>Eating foods high in fiber</li>
                        <ul>
                            <li>broccoli</li>
                            <li>Asparagus</li>
                            <li>Green Peas</li>
                            <li>Apples</li>
                        </ul>
                    <li>Exercising regularly</li>
                    <li>Staying hydrated</li>
                </ul>
        </article>
    )
}

export default TypeTwoPoop;