import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import './footer.style.scss';

const Footer = () => {

    return (
        <footer className="footer">
            <Divider/>
            <div className="footer-container">
                <div className="logo">
                    <Link to="/" className="link">
                        <h2>
                        Poop Info   
                        </h2>
                    </Link>
                </div>
                <div className="links">
                <ul className="list-items">
                    <li className="item">
                        <Link to="/poop-trivia" className="link">
                            Poop trivia
                        </Link>
                    </li>
                    <li className="item" >
                        <Link to="/about-us" className="link">
                            About us
                        </Link>
                    </li>
                    <li className="item">
                        <Link to="/contact-us" className="link">
                            Contact us
                        </Link>
                    </li>
                </ul>
                </div>
            </div>
            <Divider/>
        </footer>
    )
}
export default Footer