import {  NavLink } from "react-router-dom";

import './poop-type.style.scss';

interface Props {
  imageUrl: string,
  type: string
}
const PoopType = ({ imageUrl, type}: Props) => {
  return (
    <NavLink   to={`/poop-types/${type}`} className="poop_type-link">
      <div className="poop-type">
        <img src={`/images/poop-types/${imageUrl}`}  alt="type of poop" />
      </div>
    </NavLink>
    
  )   
}

export default PoopType;