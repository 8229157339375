import { Helmet } from "react-helmet";

const TypeSixPoop = () => {
    return (
        <article>
            <Helmet>
                <title>Type 6 poop (Mushy poop)</title>
                <meta name="description" content="This type of poop is mushy, learn more about this type of poop"/>  
            </Helmet>
            <h1>Type 6 poop (Mushy poop)</h1>
            <p>This type of poop is mushy and has rugged edges. This type of poop is not normal. 
                The cause for this type of poop could related to stress, inflamation, medication, diet or activity level.
            </p>
            <h2>Remedies:</h2>
             <p>Becase the causes are so varied it is best to make an appointment with you doctor</p>
        </article>
        
    )
}

export default TypeSixPoop;