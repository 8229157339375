import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Color from "./color/color.component";

import './poop-color.style.scss'

const colors = [
    {
       color: "brown",
       id: 1, 
    },
    {
        color: "green",
        id: 2, 
    },
    {
        color: "black",
        id: 3, 
    },
    {
        color: "white",
        id: 4, 
    },
    {
        color: "yellow",
        id: 5, 
    },
    {
        color: "red",
        id: 6, 
    }
]

const PoopColor = () => {
    return (
        <Fragment>
          <header className="poop-color_header">
            <h1>The colors of <span className="poop">poop</span></h1>
          </header>
          <section>
            <ul className="color-items">
                {
                    colors.map(({color, id})=> (
                        <li className="list-item" key={id}>
                            <Color color={color} />
                        </li>
                    ))
                }    
            </ul>
           
          </section>
          <section>
            <Outlet />
          </section>
        </Fragment>
    )
}

export default PoopColor;