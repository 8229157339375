import { Helmet } from "react-helmet";

const TypeOnePoop = () => {
    return (
        <article className="poop-type-description">
            <Helmet>
                <title>Type 1 Poop (Pebble Poop)</title>
                <meta name="description" content="Why is my poop small pebbles? This type of poop is related to constipation."/>  
           </Helmet>
            <h2>Type 1 Poop (Pebble Poop)</h2>

            <p>
                Type 1 poop or Pebble Poop refers to small pieces of poop that is often difficult to pass. This is normally a signal that poop is moving slower than expected through the digestive track.
            </p>

            <p>
               The pebbles are peiece of poop that have separated from a larger poop mass further up the digestive track. This is a sign of constipation.
            </p>

            <h2>Remedies:</h2>
                <p>
                    The remedies for pebble poop targets the underlying issue which is constipation. Some options to consider are:
                    <ul>
                        <li>Eating foods high in fiber</li>
                            <ul>
                                <li>broccoli</li>
                                <li>Asparagus</li>
                                <li>Green Peas</li>
                                <li>Apples</li>
                            </ul>
                        <li>Exercising regularly</li>
                        <li>Staying hydrated</li>
                    </ul>
                </p>  
             
        </article>
    )
}

export default TypeOnePoop;