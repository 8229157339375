import { Helmet } from "react-helmet";

const BlackPoop = () => {
    return (
        <article>
          <Helmet>
            <title>Poop Info - Black poop</title>
            <meta name="description" content="Poop Info - Why is my poop black? Black poop signifies that there is a problem."/>  
         </Helmet>
            <h2>Black Poop</h2>
            <p>
                Black poop is normally a sign that there is a problem. Whereas black poop can be caused certain foods, it can also be an indication that there is blood in the gastrointesninal track.
            </p>
            <p>
                Certain medications and supplements are also another reason for black poop.
            </p>
            
            <h2>Remedies:</h2>
            <p>If you believe foods consumed (such as blueberries) are not the cause of your black poop you should consult your doctor</p>
        </article>
    )
}

export default BlackPoop;