
import { Card as MUICard, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'

interface CardProps {
  altText: string;
  imageUrl: string;
  title: string;
  description?: string;
  href: string
}

const Card = (props: CardProps) =>  {
  return (
    <MUICard sx={{ maxWidth: 300 }}>
      <CardActionArea href={props.href}>
        <CardMedia
          component="img"
          height="140"
          image={props.imageUrl}
          alt={ props.altText }
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </MUICard>
  );
}
export default Card;
