import { Helmet } from "react-helmet";

const RedPoop = () => {
    return (
        <article>
            <Helmet>
                <title>Poop Info - Red Poop</title>
                <meta name="description" content="Poop Info - Why is my poop red? Let's talk about red poop. Red poop should not immediately raise and alarm. This page focus more on causes of red poop."/>  
           </Helmet>
            <h2>Red Poop</h2>
            <p>
                Inviduals who find that their poop is red should not be immediately alarm. Once of the causes of red stool is the consumption of food like beets.
                However, more alarming causes for red poop are inflamation in the bowels, anal fishers and hermorrhoids.
            </p>

            <h2>Remedies:</h2>
            <ul>
                <li>If you have been consuming red foods such as beets, drink lots of water and wait for a few days</li>
                <li>If redness of your poop does not correlate to diet, please see your doctor.</li>
            </ul>
        </article>
    )
}

export default RedPoop;