import { Helmet } from "react-helmet";

const YellowPoop = () => {
    return (
        <article>
            <Helmet>
                <title>Poop Info - Yellow Poop</title>
                <meta name="description" content="Poop Info - Why is my poop yellow? Let's talk about yellow poop. Yellow poop should not immediately raise and alarm. This page focuses more on causes of yellow poop."/>  
           </Helmet>
            <h2>Yellop poop</h2>
            <p>
               Poop appears in several colors. One of these colors is yellow. This color poop can be a result of certain folds. It can also be the result of number health problems.

            </p>
            <p>Some of these issues iclude:
                <ul>
                    <li>
                        Malfunction Gallbladder
                    </li>
                    <li>
                        Celliac dissease
                    </li>
                    <li>
                        Pancreatic disorder
                    </li>
                </ul>
            </p>
            <h2>Remedies:</h2>
            <p>Consult your physician</p>
        </article>
    )
}

export default YellowPoop;