
import { Fragment, MutableRefObject, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import "./contact-us.style.scss";
import { Helmet } from 'react-helmet';

const ContactUs = () => {
    const form = useRef<HTMLFormElement>() as MutableRefObject<HTMLFormElement>;
    const [showConfirmation, setConfirmation] = useState(false)
    const sendEmail = (event: any) => {
      event.preventDefault();
  
      emailjs.sendForm('service_gm63uqr', 'template_x5h7vat', form.current, 'B61lw8tnivMECFaFw')
        .then(() => {
            setConfirmation(true)
        });
    };
  
    return (
        <Fragment>
        <Helmet>
          <title>Contact Us</title>
          <meta name="description" content="Use our contact us form to reach out. We would love to hear from you"/>        
        </Helmet>
            {
                !showConfirmation && 
                <Fragment>
                        <h1 className='contact-us__title'>We would love to hear from you</h1>
                        <section className='contact-us'>
                            <form ref={form} onSubmit={sendEmail} className="contact-us__form">
                                <div>
                                    <label>Name</label>
                                    <input type="text" name="user_name" required />  
                                    </div>
                                <div>
                                    <label>Email</label>
                                    <input type="email" name="user_email" required/>
                                </div>
                                <div>
                                    <label>Message</label>
                                    <textarea name="message" required/>
                                </div>
                                <input type="submit" value="Send" />
                            </form> 
                        </section> 
                </Fragment>
            }
            {
                showConfirmation && <Fragment>
                    <h1 className='contact-us__confirmation'>Thank you for contacting us.</h1>
                </Fragment>
            }
            
        </Fragment>
       
    );
}

export default ContactUs;