import { Helmet } from "react-helmet";

const BrownPoop = () => {
    return (
        <article>
           <Helmet>
            <title>Poop Info - Brown Poop</title>
            <meta name="description" content="Poop Info - Why is my poop brown? Brown poop is normal and is a good sign that you liver is producing bile which gives poop its brown color."/>  
           </Helmet>
            <h2>Brown poop</h2>
            <p>This type poop is normal and is what one should expect to see.</p>
            <p>The majority of poop is made of water. The remainder is made up of other things such as, red blood cells, fat, bacteria, and bile</p>
            <p>The brown color of poop due largely to the production of bile by the liver.</p>
        </article>
    )
}

export default BrownPoop;