import { Helmet } from "react-helmet";

const GreenPoop = () => {
    return (
        <article>
           <Helmet>
            <title>Poop Info - Green Poop</title>
            <meta name="description" content="Poop Info - Why is my poop green? Let's talk about green poop. This is normally casued by something you ate."/>  
           </Helmet>
            <h2>Green poop</h2>
            <p>Green poop is normally caused by something you ate such a green leafy vegetables. Green poop can also be caused by certain medications.</p>
            <h2>Remedies:</h2>
            <p>
                There are no remedies, poop color normally returns to normal after a few days.
            </p>
        </article>
    )
}
export default GreenPoop;