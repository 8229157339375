import { Helmet } from "react-helmet";
import { H1Title, HeaderImg } from "./coffee-poop.style";

const CoffeeAndPoop = () => {

    return <article className="coffee-poop">
         <Helmet>
          <title>Why coffee makes you poop?</title>
          <meta name="description" content="The reason why coffee makes you poop"/>        
        </Helmet>
        
        <H1Title>The Real Reason Why Coffee Makes You Poop</H1Title>
        
        <HeaderImg src="/images/coffee.webp"/>
        
        <p>
            Do you tumble out of bed and stumble to the kitchen to pour yourself a morning cup of ambition, 
            otherwise known as coffee? Millions of people do, and while your mind is waking up 
            with that heavenly cup of coffee, so too is your body.
        </p>
        <p>
            In fact, for many, that means a sudden need to get to the bathroom. 
            What is the real reason why coffee makes you poop? As it turns out, 
            there’s a scientific explanation. 
        </p>
        <h2>
            Coffee’s Effect on Your Gut
        </h2>
        <p>
            Researchers are still trying to understand exactly what compounds are in coffee. 
            For now, they’ve found over 1,000 of them, but their effects on the gut are still a mystery. 
        </p>
        <p>
            One thing that is known, though, is that your stomach creates <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7824117/">a hormone known as gastrin.</a>
            Drinking coffee causes your stomach to release this hormone, which sets gut contractions 
            or peristalsis into motion. This process is what moves any food and liquids you consume through your intestines. 
            For some, this can cause an almost immediate need to vacate the bowels.
        </p>
        <h2>
            Does Coffee Make You Poop Because of Caffeine?
        </h2>
        <p>
            Does Coffee Make You Poop Because of Caffeine? Interestingly, 
            while caffeine gets the body going and was long believed to be what coffee makes you poop, 
            the pooping effect also happens with decaffeinated coffee. <a href="https://pubmed.ncbi.nlm.nih.gov/3745848/">Both types of coffee</a> 
            kick off the release of gastrin, however, decaf causes the release of a smaller amount of gastrin, but it still does the trick. 
        </p>
        <p>
            Caffeine certainly makes a difference, and it tends to <a href="https://pubmed.ncbi.nlm.nih.gov/18350336/">relax the sphincter</a>, 
            making it easier to poop and causing more <a href="https://pubmed.ncbi.nlm.nih.gov/9581985/">contractions in the large intestine</a>. 
            But it may have more to do with other substances in coffee, 
            whether regular or decaf, that lead you to the bathroom after your first cup. After all, many sodas contain caffeine, yet they aren’t
            associated with the need to have a bowel movement which shows there’s much more to coffee and pooping than caffeine. 
        </p>
        <h2>Why Does That Morning Cup of Coffee Make You Poop?</h2>
        <p>
            Whenever you eat or drink anything, there’s a reflex called the gastrocolic 
            reflex responsible for moving things through the intestines. It is choreographed 
            with your muscles, nerves, and hormones to help your body get rid of whatever it doesn’t need. 
        </p>
        <p>
            But if you have coffee in the afternoon to pick yourself up after 
            lunch and don’t poop, this may make you wonder why that morning coffee 
            has this effect. Quite simply, you poop after that morning cup of coffee 
            because that gastrocolic reflex is by nature much more active in the morning. 
        </p>
        <p>
            That’s certainly good news if you’re meeting someone for an afternoon coffee and don’t 
            want to disappear into the bathroom for a while. Though if you’re meeting 
            them in the morning, you may want to make it after you’ve already had your first cup and your first visit to the bathroom. 
        </p>
        <h2>
            Will Other Coffee Drinks Cause You to Poop Too?
        </h2>
        <p>
            Any coffee can make you poop first thing in the morning, whether it has caffeine or not. 
            Other items added to your coffee may also bring on those bowel movements—for example, dairy 
            products such as cream or milk. Should you experience gas, bloating, and loose stools after 
            having anything with dairy, it’s likely the cause.
        </p>
        <p>
            While there might be some minor differences in the amount of caffeine and acids contained in 
            different types of coffee, from light roast to dark roast or even for cold brew, there’s not 
            enough difference to cause a drastic change. The result is that all coffee drinks have the 
            potential to kick off your bowel movements for the day.
        </p>
        <h2>
            Is it a Good Idea to Drink Coffee in the Morning for Bowel Movements?
        </h2>
        <p>
            It’s undoubtedly an excellent way to start the day when you’ve cleared out your bowels. 
            Coffee has plenty of <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4665516/#:~:text=Antioxidant%20activity%20of%20coffee%20is,be%20antioxidants%20also%20%5B14%5D.">antioxidants</a>  that 
            benefit your overall health, though if you want to stay 
            regular, you need to do more than have coffee every morning.  
        </p>
        <p>
            A healthy bowel begins with a diet that is rich in fiber as well as nutritious overall. 
            Additionally, getting regular exercise activates the digestive system. Proper hydration 
            will keep things moving along as they should and prevent you from being backed up. While 
            coffee is a liquid and a form of hydration, caffeine can work against you by causing dehydration. 
            A smart move is to balance your coffee consumption with ample water consumption. 
        </p>
        <p>
            If you find coffee helpful with making a morning bowel movement, there’s nothing wrong with that. 
            It is best to limit your consumption to four 8-ounce cups of regular coffee so that you don’t ingest 
            too much caffeine daily. For those that feel backed up, it can be a huge relief to start the day with a 
            cup of coffee to get everything out. Even <a href="https://www.acog.org/womens-health/experts-and-stories/ask-acog/how-much-coffee-can-i-drink-while-pregnant">pregnant women</a>, 
            who notoriously battle constipation in different stages of pregnancy, can safely consume coffee as long as they keep it limited to no more than two 8-ounce cups per day.
        </p>
        <h2>
            Coffee for Triggering Your Gut Reaction to Poop
        </h2>
        <p>
            In short, coffee makes you poop because the compounds it contains stimulate a hormone in your stomach. 
            As this hormone is released, it causes contractions in the digestive system that get 
            things moving. For some people, this is relatively instant; for others, it may take a little time for 
            the body to respond to that first cup of coffee in the morning.
        </p>
        <p>
            This reflex is strongest in the morning, so you’re not likely to have an urgent need to poop with an 
            afternoon coffee. If you want to feel light and ready for the day, make time to enjoy your coffee 
            before you have to go anywhere and start the morning off right. 
        </p>
    </article>
}

export default CoffeeAndPoop;