import { Chip, Divider } from "@mui/material";
import { CardsWrapper, StyledContainer, } from "./cards-container.style";

interface Props {
    children: JSX.Element
}
const CardsContainer = ({ children }: Props) => {
    return <StyledContainer>
        <Divider>
            <Chip label="Explore more" />
        </Divider>
        <CardsWrapper>
            { children }
        </CardsWrapper>
        
    </StyledContainer>
}

export default CardsContainer;