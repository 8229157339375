import { Helmet } from "react-helmet";

const TypeSevenPoop = () => {
    return (
        <article>
            <Helmet>
                <title>Type 7 poop (Watery)</title>
                <meta name="description" content="This type of poop is watery, learn more about this type of poop"/>  
            </Helmet>
            <h2>Type 7 poop (Watery)</h2>
            <p>This type of poop is completely liquid and is a strong indication of Diarrhea. This is normally due to inflamation in the small intentines.</p>
            <p>Fortunately, diarrhea normally only last for a few days. However, if it persist beyond a few days we recommend seeing a docter. </p>
            <h2>Remedies:</h2>
             <ul>
                <li>Consume electrolytes and stay hydrated as diarrhea can cause dehydration</li>
                <li>Consult your doctor is diarrhea persist beyond few days.</li>
             </ul>
        </article>
    )
}

export default TypeSevenPoop;